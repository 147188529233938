import { withPageData } from "../../utils/common-page-data/common-page-data";
import DigitalCampaignPage from "../digitalcampaign/[slug]";
import { getCampaignPageProps } from "../digitalcampaign/getServerSideProps";

/**
 * Campaign page for Sweden
 */
export default DigitalCampaignPage;

export const getServerSideProps = withPageData(getCampaignPageProps);
